import styled from 'styled-components'

export const ContactBarLink = styled.a`
  padding-right: 0.5em !important;
`

const styles = {
  ContactBarLink,
}

export default styles
